import { cognito } from "../config/reducerproperties";
import TokenHelper from "./tokenhelper";
import axios from "axios";
import router from "../router";
import { EventBus } from "../components/eventbus";
import Console from "@/console";
import UserSettingsHelper from "@/helper/usersettingshelper";
import QRCode from "qrcode";

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: cognito.userPoolId,
  ClientId: cognito.clientId
};
var userPool = new CognitoUserPool(poolData);

class CognitoAuth {
  static signin(username, pass, cognitoFail) {
    var authenticationData = {
      Username: username,
      Password: pass
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: CognitoAuth.processSuccessSignin,
      onFailure: cognitoFail,
      mfaSetup: function(challengeName) {
        if (challengeName === "SOFTWARE_TOKEN_MFA_SETUP" || challengeName === "MFA_SETUP") {
          cognitoUser.associateSoftwareToken({
            ...this,
            associateSecretCode: secretCode =>
              CognitoAuth.displayQRCode(secretCode, cognitoUser, username)
          });
        } else {
          Console.error("Unknown challenge:", challengeName);
        }
      },
      totpRequired: function(challengeName, challengeParameters) {
        if (challengeName === "SOFTWARE_TOKEN_MFA") {
          CognitoAuth.showMfaModal(code =>
            cognitoUser.sendMFACode(
              code,
              {
                onSuccess: CognitoAuth.processSuccessSignin,
                onFailure: res => CognitoAuth.showMfaError(res.message)
              },
              "SOFTWARE_TOKEN_MFA"
            )
          );
        } else {
          Console.error("Unknown challenge:", challengeName);
        }
      }
    });
  }

  static processSuccessSignin(result) {
    EventBus.$emit("hide-global-modal", "mfa-modal");

    //Tokens are automatically added to local storage by SDK
    var accessToken = result.getAccessToken().getJwtToken();
    //Put the token in the header of all http requests
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    EventBus.$emit("user-signed-in");

    if (localStorage.redirect) {
      router.push({
        path: localStorage.redirect
      });
      localStorage.removeItem("redirect");
    } else {
      router.push({
        name: "dashboard"
      });
    }
  }

  static associateAndSetupSoftwareToken(cognitoUser, email) {
    cognitoUser.associateSoftwareToken({
      onSuccess: function(secretCode) {
        CognitoAuth.verifySoftwareTokenPrompt(cognitoUser);
      },
      onFailure: function(err) {
        Console.error("associateSoftwareToken error:", err);
      },
      associateSecretCode: secretCode => CognitoAuth.displayQRCode(secretCode, cognitoUser, email)
    });
  }

  static displayQRCode(secretCode, cognitoUser, email) {
    const issuer = "Reducer";
    const otpAuthUri = `otpauth://totp/${issuer}:${email}?secret=${secretCode}&issuer=${issuer}`;
    QRCode.toDataURL(otpAuthUri, function(err, dataURL) {
      if (err) {
        Console.error("Failed to generate QR Code:", err);
        return;
      }
      CognitoAuth.showMfaModal(
        code => CognitoAuth.verifySoftwareTokenPrompt(code, cognitoUser),
        dataURL
      );
    });
  }

  static showMfaModal(callback, otpAuthUri) {
    EventBus.$emit("show-global-modal", "mfa-modal", {
      codeEntered: callback,
      qrCode: otpAuthUri
    });
  }

  static showMfaError(message) {
    EventBus.$emit("show-error-mfa-modal", {
      message: message
    });
  }

  static verifySoftwareTokenPrompt(userEnteredTOTP, cognitoUser) {
    cognitoUser.verifySoftwareToken(userEnteredTOTP, "myTokenName", {
      onSuccess: CognitoAuth.processSuccessSignin,
      onFailure: res => CognitoAuth.showMfaError(res.message)
    });
  }

  static signup(username, password, cognitoCallback) {
    var attributeList = [];
    userPool.signUp(username, password, attributeList, null, cognitoCallback);
  }

  static async getCurrentUserAccessToken() {
    return new Promise(resolve => {
      var cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        let token = cognitoUser.getSession((err, session) => {
          if (err) {
            Console.error("Failed to get user access token from local store");
            Console.error(err.message);
            resolve(false);
          } else {
            resolve(session.getAccessToken().getJwtToken());
          }
        });

        if (token) {
          resolve(token);
        }
      } else {
        resolve(false);
      }
    });
  }

  static async getCurrentUserIdToken() {
    return new Promise(resolve => {
      var cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        let token = cognitoUser.getSession((err, session) => {
          if (err) {
            Console.error("Failed to get user access token from local store");
            Console.error(err.message);
            resolve(false);
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        });

        if (token) {
          resolve(token);
        }
      } else {
        resolve(false);
      }
    });
  }

  static async getCurrentUserEmail() {
    var idJWTToken = await this.getCurrentUserIdToken();
    var idToken = TokenHelper.parseJwtToken(idJWTToken);
    Console.log(idToken.email);
    return idToken.email;
  }

  static confirmRegistration(username, code, cognitoCallback) {
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, cognitoCallback);
  }

  static resendConfirmationCode(username, cognitoCallback) {
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(cognitoCallback);
  }

  static forgotPassword(username, cognitoSuccess, cognitoFail) {
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: cognitoSuccess,
      onFailure: cognitoFail
    });
  }

  static async changePassword(oldPassword, newPassword, cognitoSuccess, cognitoFail) {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          Console.error("Failed to get user access token from local store: ", err.message);
          return cognitoFail("Sorry, an error occured and we could not change your password.");
        }
      });
    }

    cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
      return err ? cognitoFail(err) : cognitoSuccess();
    });
  }

  static confirmPassword(username, password, code, cognitoSuccess, cognitoFail) {
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(code, password, {
      onSuccess: cognitoSuccess,
      onFailure: cognitoFail
    });
  }

  static signout() {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      //Automatically removes tokens from local storage
      cognitoUser.signOut();
      UserSettingsHelper.removeSettings();

      //Remove access token from http client
      delete axios.defaults.headers.common["Authorization"];

      EventBus.$emit("user-signed-out");

      //Use Cognito LOGOUT endpoint to ensure social identifier sessions are ended
      var signoutPath =
        cognito.endPoint +
        "/logout" +
        "?client_id=" +
        cognito.clientId +
        "&logout_uri=" +
        cognito.signoutUrl;
      //Vue router does not support absolute path, so use javascript
      window.location.replace(signoutPath);
    }
  }

  static async isCurrentUserAdmin() {
    return CognitoAuth.isTokenAdmin(await CognitoAuth.currentUserParsedToken());
  }

  static async isCurrentUserDev() {
    return CognitoAuth.isTokenDev(await CognitoAuth.currentUserParsedToken());
  }

  static async currentUserParsedToken() {
    var parsedToken;
    var rawToken = await CognitoAuth.getCurrentUserAccessToken();

    if (rawToken) {
      parsedToken = TokenHelper.parseJwtToken(rawToken);
    }

    return parsedToken;
  }

  static isTokenAdmin(token) {
    return CognitoAuth.hasGroup(token, "admin");
  }

  static isTokenDev(token) {
    return CognitoAuth.isTokenAdmin(token) && CognitoAuth.hasGroup(token, "dev");
  }

  static hasGroup(token, name) {
    var groups,
      hasGroup = false;
    if (token && (groups = token["cognito:groups"])) {
      if (groups.includes(name)) {
        hasGroup = true;
      }
    }

    return hasGroup;
  }

  static async isLoggedIn() {
    return (await CognitoAuth.getCurrentUserAccessToken()) != false;
  }

  static createUserSession(access_token, id_token, refresh_token) {
    const AccessToken = new CognitoAccessToken({ AccessToken: access_token });
    const IdToken = new CognitoIdToken({ IdToken: id_token });
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refresh_token
    });

    const sessionData = {
      AccessToken: AccessToken,
      IdToken: IdToken,
      RefreshToken: RefreshToken
    };

    const userSession = new CognitoUserSession(sessionData);

    var token = TokenHelper.parseJwtToken(id_token);
    const userData = {
      Username: token.email,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setSignInUserSession(userSession);

    cognitoUser.getSession(function(err, session) {
      if (session.isValid) {
        if (localStorage.redirect) {
          router.push({
            path: localStorage.redirect
          });
          localStorage.removeItem("redirect");
        } else {
          router.push({
            name: "dashboard"
          });
        }
      } else {
        // TODO
      }
    });
  }
}
export default CognitoAuth;
