<template>
  <b-toast
    id="initiating-analysis-toast"
    toaster="global-toaster"
    :visible="visible"
    :auto-hide-delay="25000"
  >
    <template #toast-title>
      <b-spinner small type="grow" variant="secondary" class="mr-2" />
      Initiating Analysis
    </template>
    Please wait, we're starting a new analysis.
  </b-toast>
</template>

<script>
import { EventBus } from "@/components/eventbus";

export default {
  name: "AnalysisToast",
  data() {
    return {
      visible: false
    };
  },
  created() {
    EventBus.$on("show-analysis-toast", () => (this.visible = true));
    EventBus.$on("hide-analysis-toast", () => (this.visible = false));
  }
};
</script>