<template>
  <div>
    <b-row>
      <b-col cols="6" class="d-inline-block">
        <b-dropdown-item :to="{ name: 'all-extracts' }">Extracts</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'get-suppliers' }">Suppliers</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'magic-links' }">Magic Links</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'email' }">Email</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'email-events' }">Email Events</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'scheduled-emails' }">Scheduled Emails</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'requested-jobs' }">Requested Jobs</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'admin-get-contracts' }">Contracts Tracker</b-dropdown-item>
        <b-dropdown-item
          v-if="$appType == 'reducer'"
          target="_blank"
          href="https://us-east-1.quicksight.aws.amazon.com/sn/accounts/348757164585/dashboards/5fe8c1b7-924d-4ddd-9799-fd7fa265c458"
          >QuickSight</b-dropdown-item
        >
      </b-col>
      <b-col cols="6" class="d-inline-block">
        <b-dropdown-item :to="{ name: 'all-organisations' }">Organisations</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'all-users' }">Users</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'admin-organisations-savings-explorer' }"
          >Savings Explorer</b-dropdown-item
        >
        <b-dropdown-item :to="{ name: 'admin-get-expiring-deals' }">Expiring Deals</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'all-invoices' }">Invoices</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'consent-lists', params: { userId: null } }"
          >Consent Lists</b-dropdown-item
        >
        <b-dropdown-item :to="{ name: 'textracts' }">Textracts</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'pricing-hub' }">Pricing Hub</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'sales' }">Sales Bible</b-dropdown-item>
      </b-col>
      <b-col v-if="showDev" cols="12">
        <b-dropdown-divider />
        <b-dropdown-item :to="{ name: 'developer' }">Developer</b-dropdown-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AdminTabs",
  props: {
    showDev: Boolean
  }
};
</script>
