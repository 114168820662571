<template>
  <b-modal
    id="unsaved-data-modal"
    cancel-title="Go back"
    ok-title="Proceed"
    @ok="proceed"
    hide-header
  >
    There's some unsaved data!
  </b-modal>
</template>

<script>
import { EventBus } from "@/components/eventbus";
export default {
  name: "UnsavedDataModal",
  data() {
    return {
      callback: null
    };
  },
  mounted() {
    EventBus.$on("show-unsaved-data-modal", callback => {
      this.callback = callback;
      this.$bvModal.show("unsaved-data-modal");
    });
  },
  methods: {
    proceed() {
      if (this.callback) {
        this.callback();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
