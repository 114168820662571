<template>
  <div>
    <b-dropdown-item
      v-if="display('organisation-data')"
      :to="{
        name: 'organisation-data',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'table']" class="text-muted mr-2" />Data
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('spotlight')"
      :to="{
        name: 'spotlight',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'whale']" class="text-muted mr-2" />Spotlight
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('organisation-data-upload')"
      :to="{
        name: 'organisation-data-upload',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'file-upload']" class="text-muted mr-2" />Upload data
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('organisation-extracts')"
      :to="{
        name: 'organisation-extracts',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'cubes']" class="text-muted mr-2" />Extracts
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('reports')"
      :to="{ name: 'reports', params: { organisationId: orgId } }"
    >
      <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="text-muted mr-2" />Reports
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('organisation-details')"
      :to="{
        name: 'organisation-details',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'info-circle']" class="text-muted mr-2" />Details
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('organisation-events')"
      :to="{
        name: 'organisation-events',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'calendar-check']" class="text-muted mr-2" />Events
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('textract-result')"
      :to="{
        name: 'textract-result',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'alien-monster']" class="text-muted mr-2" />Textract
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('admin-get-contracts')"
      :to="{
        name: 'admin-get-contracts',
        query: { orgId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'file-contract']" class="text-muted mr-2" />Contracts
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('sales-record')"
      :to="{
        name: 'sales-record',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'chart-network']" class="text-muted mr-2" />Deal
    </b-dropdown-item>
    <slot></slot>
    <b-dropdown-divider v-if="display('savings-editor')" />
    <b-dropdown-item
      v-if="display('organisation-dashboard')"
      :to="{ name: 'organisation-dashboard', params: { organisationId: orgId } }"
    >
      <font-awesome-icon :icon="['fas', 'hotel']" class="text-muted mr-2" />Dashboard
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('savings')"
      :to="{
        name: 'savings',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'pound-sign']" class="text-muted mr-2" />Savings
      <b-badge variant="danger" v-if="archived">Archived</b-badge>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display('savings-editor')"
      :to="{
        name: 'savings-editor',
        params: { organisationId: orgId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'unicorn']" class="text-muted mr-1" />Savings editor
    </b-dropdown-item>
  </div>
</template>

<script>
export default {
  name: "OrganisationTabs",
  props: {
    organisationId: String,
    archived: Boolean,
    links: Array
  },
  computed: {
    orgId: function() {
      return this.organisationId ? this.organisationId : this.$route.params.organisationId;
    }
  },
  methods: {
    display: function(pageName) {
      return this.links ? this.links.includes(pageName) : true;
    }
  }
};
</script>
