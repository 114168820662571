var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.display('organisation-data'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-data',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'table']}}),_vm._v("Data ")],1):_vm._e(),(_vm.display('spotlight'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'spotlight',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'whale']}}),_vm._v("Spotlight ")],1):_vm._e(),(_vm.display('organisation-data-upload'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-data-upload',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'file-upload']}}),_vm._v("Upload data ")],1):_vm._e(),(_vm.display('organisation-extracts'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-extracts',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'cubes']}}),_vm._v("Extracts ")],1):_vm._e(),(_vm.display('reports'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'reports', params: { organisationId: _vm.orgId } }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'file-invoice-dollar']}}),_vm._v("Reports ")],1):_vm._e(),(_vm.display('organisation-details'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-details',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}}),_vm._v("Details ")],1):_vm._e(),(_vm.display('organisation-events'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-events',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'calendar-check']}}),_vm._v("Events ")],1):_vm._e(),(_vm.display('textract-result'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'textract-result',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'alien-monster']}}),_vm._v("Textract ")],1):_vm._e(),(_vm.display('admin-get-contracts'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'admin-get-contracts',
      query: { orgId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'file-contract']}}),_vm._v("Contracts ")],1):_vm._e(),(_vm.display('sales-record'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'sales-record',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'chart-network']}}),_vm._v("Deal ")],1):_vm._e(),_vm._t("default"),(_vm.display('savings-editor'))?_c('b-dropdown-divider'):_vm._e(),(_vm.display('organisation-dashboard'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'organisation-dashboard', params: { organisationId: _vm.orgId } }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'hotel']}}),_vm._v("Dashboard ")],1):_vm._e(),(_vm.display('savings'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'savings',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'pound-sign']}}),_vm._v("Savings "),(_vm.archived)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Archived")]):_vm._e()],1):_vm._e(),(_vm.display('savings-editor'))?_c('b-dropdown-item',{attrs:{"to":{
      name: 'savings-editor',
      params: { organisationId: _vm.orgId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-1",attrs:{"icon":['fas', 'unicorn']}}),_vm._v("Savings editor ")],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }