import moment from "moment";
import { app } from "@/config/reducerproperties";

class FormatHelper {
  static isValidPostcode(postcode) {
    postcode = postcode.replace(/\s/g, "");
    var regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
    return regex.test(postcode);
  }

  static postcodeOutwardPart(postcode) {
    postcode = postcode.replace(/\s/g, "");
    if (postcode.length > 4) {
      return postcode.substring(0, postcode.length - 3);
    } else {
      return null;
    }
  }

  static isValidHexColor(value) {
    //^          -> match beginning
    //#          -> a hash
    //[0-9A-F]   -> any integer from 0 to 9 and any letter from A to F
    //{6}        -> the previous group appears exactly 6 times
    //$          -> match end
    //i          -> ignore case
    var re = /^#[0-9A-F]{6}$/i;
    return re.test(String(value).toLowerCase());
  }

  static formatNumberToDisplay(value) {
    if ((value || value == 0) && typeof value != "undefined") {
      return parseInt(value).toLocaleString();
    } else return "";
  }

  static formatFloatToDisplay(value) {
    if ((value || value == 0) && typeof value != "undefined") {
      return parseFloat(value).toLocaleString();
    } else return "";
  }

  static formatPrice(price) {
    if (!price || price == 0) {
      return "Free";
    } else return app.currencySymbol + price.toFixed(2);
  }

  static formatTwoDecimals(value) {
    if ((value || value == 0) && typeof value != "undefined") {
      const number = parseFloat(value.toFixed(2));
      return number.toLocaleString("en-US"); // US format
    } else return "";
  }

  static formatPoundToDisplay(value) {
    if ((value || value == 0) && typeof value != "undefined") {
      return app.currencySymbol + parseInt(value).toLocaleString();
    } else return "";
  }

  //example: lowercasestuff-otherlowecasestuff
  static validFriendlyName(value) {
    if (value && typeof value != "undefined") {
      var re = /^[a-zA-Z-]+$/;
      return value.length < 50 && re.test(value) && value.charAt(value.length - 1) != "-";
    } else return false;
  }

  static validateHTTP(value) {
    if (value) {
      if (!value.startsWith("http")) {
        return "https://" + value;
      } else {
        return value;
      }
    }
  }

  static formatCoboldCase(value) {
    if (value)
      return value
        .toLowerCase()
        .split("_")
        .map(function(word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(" ");
    else return value;
  }

  static formatTitleCase(value) {
    if (value) {
      value = value.replaceAll("_", " ");
      return value.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return "";
    }
  }

  // camelCase => Camel Case
  static formatCamelCaseToTitleCase(value) {
    if (value) {
      return value.replace(/([A-Z])/g, " $1").replace(/^./, s => s.toUpperCase());
    }
  }

  static formatMonthsToYears(value) {
    var result = value + " month";
    if (value % 12 == 0) {
      value /= 12;
      result = value + " year";
    }

    return value > 1 ? result + "s" : result;
  }

  static formatDateMonYear(value) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format("MMM-YYYY");
    }
    return formatted;
  }

  static formatDate(value) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format("DD/MM/YY");
    }
    return formatted;
  }

  static formatDateFullYear(value) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format("DD/MM/YYYY");
    }
    return formatted;
  }

  static formatDateTimeToMin(value) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
    return formatted;
  }

  static formatDateTimeToSec(value) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
    }
    return formatted;
  }

  static formatDateTimeCustom(value, format) {
    var formatted = "";
    if (value) {
      formatted = moment(String(value)).format(format);
    }
    return formatted;
  }

  static formatDateToLocalDateTime(value, start = true) {
    if (value && typeof value != "undefined") {
      var formatted = this.formatDateTimeCustom(value, "YYYY-MM-DD");
      if (start) {
        return formatted + "T00:00:00";
      } else {
        return formatted + "T23:59:59.599Z";
      }
    }
  }

  static formatDateStringWithTime(value, start = true) {
    if (value && typeof value != "undefined") {
      if (start) {
        return value + "T00:00:00.000Z";
      } else {
        return value + "T23:59:59.599Z";
      }
    } else {
      return "";
    }
  }

  static formatEpochToHumanReadableTime(value) {
    var myDate = new Date(value * 1000);
    return myDate.toLocaleString().replace(",", "");
  }

  static formatCurrency(value) {
    var formatted = "";

    if (value || value == 0) {
      if (typeof value == "string") {
        value = parseFloat(value);
      }
      formatted = value.toFixed(2).toString();
    }

    return formatted;
  }

  static max150(value) {
    if (value != null) {
      if (value.length > 150) {
        value = value.substring(0, 150) + "...";
      }
    }
    return value;
  }

  static formatSortCode(value) {
    if (value != null) {
      if (value.length == 6) {
        return value.substr(0, 2) + "-" + value.substr(2, 2) + "-" + value.substr(4, 2);
      }
    }
    return value;
  }

  static formatInvoiceType(value) {
    var formatted = "";
    if (value === "INVOICE") {
      formatted = "INV";
    }
    if (value === "BANKTRANSACTION") {
      formatted = "BT";
    }
    if (value === "BILL") {
      formatted = "BIL";
    }
    if (value === "PAYMENT") {
      formatted = "PAY";
    }
    if (value === "PURCHASE") {
      formatted = "PUR";
    }
    return formatted;
  }

  static getSavingsState(status, report) {
    if (
      report &&
      typeof report != undefined &&
      status != "ERROR" &&
      status != "GATHERING" &&
      status != "QUEUED"
    ) {
      if (report.archived) {
        status = "ARCHIVED";
      } else if (report.state == "noneFound") {
        status = "NOSAVINGS";
      } else if (report.state == "noneFoundInsufficientData") {
        status = "NOSAVINGSDATA";
      } else if (report.state == "noneFoundNoMatches") {
        status = "NOSAVINGSMATCHES";
      }
    }

    return status;
  }

  static formatSavingsState(state) {
    var string = null;

    if (state != null) {
      switch (state) {
        case "ERROR":
          string = "Data gathering error";
          break;
        case "QUEUED":
          string = "Analysis queued";
          break;
        case "GATHERING":
          string = "Gathering data";
          break;
        case "NOT_REQUESTED":
          string = "Analysis not yet requested";
          break;
        case "REPORT_COMPLETE":
          string = "Report complete";
          break;
        case "REPORT_GENERATION":
          string = "Report in progress";
          break;
        case "ARCHIVED":
          string = "New report in progress";
          break;
        case "NOSAVINGS":
          string = "No spend";
          break;
        case "NOSAVINGSDATA":
          string = "Insufficient data for report";
          break;
        case "NOSAVINGSMATCHES":
          string = "No savings found";
          break;
      }
    }

    return string;
  }

  static orgShortName(name) {
    if (name) {
      name = name.replace(/\sltd$|\slimited$/gi, "");
    }
    return name;
  }

  static validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static isExistingField(field) {
    if (typeof field == "undefined") return false;
    return true;
  }

  static isEmpty(field) {
    if (!field || typeof field === "undefined") {
      return true;
    } else {
      return false;
    }
  }

  static formatReportState(state) {
    switch (state) {
      case "complete":
        return "Report complete";
      case "noneFound":
        return "No savings: Other";
      case "noneFoundNoMatches":
        return "No savings: No matches";
      case "noneFoundInsufficientData":
        return "No savings: Insufficient data";
    }
  }

  static displayNameFromEmail(email) {
    if (email) {
      return email
        .split("@")[0]
        .split(".")
        .map(w => w[0].toUpperCase() + w.substr(1))
        .join(" ")
        .replace(/\bMc(\w)/, (match, word) => `Mc${word.toUpperCase()}`);
    } else return "";
  }
}

export default FormatHelper;
