<template>
  <b-navbar id="main-navbar" class="navbar-custom box-shadow--4dp" toggleable="md" type="default">
    <b-navbar-brand id="brand-logo" @click.stop="logonav()">
      <img
        v-if="$appType == 'mic'"
        border="0"
        alt="Make It Cheaper"
        src="@/assets/makeitcheaper.svg"
      />
      <img v-else border="0" alt="reducer" src="@/assets/reducer_logo_path.svg" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav_dropdown_collapse"></b-navbar-toggle>

    <b-collapse is-nav id="nav_dropdown_collapse">
      <b-navbar-nav v-if="showNavItems" class="ml-auto">
        <b-nav-item :to="{ name: 'dashboard' }">Dashboard</b-nav-item>

        <b-nav-item-dropdown v-if="showAdmin && showOrgViews" text="Organisation">
          <OrganisationTabs />
        </b-nav-item-dropdown>

        <b-nav-item v-if="showAccountant" :to="{ name: 'savings-explorer' }">
          Savings Explorer
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="(!$appType || $appType == 'reducer') && !showAdmin"
          text="Guides"
          right
        >
          <b-dropdown-item
            v-for="guide in guides"
            v-bind:key="guide.uid"
            class="text-right guide-button"
            target="_blank"
            :href="'https://reducer.co.uk/guides/' + guide.uid"
            >{{ guide.data.shorttitle[0].text }}</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="navnavnav" text="Admin" right v-if="showAdmin">
          <AdminTabs :show-dev="showDev" />
        </b-nav-item-dropdown>

        <b-nav-item v-if="showConsentLists" :to="{ name: 'consent-lists' }">
          Consent Lists
        </b-nav-item>

        <b-nav-item :to="{ name: 'account' }">Account</b-nav-item>

        <b-nav-item :to="{ name: 'signout' }">Sign out</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Prismic from "@prismicio/client";
import Console from "@/console";
import OrganisationTabs from "./OrganisationTabs";
import AdminTabs from "./AdminTabs";
import { url } from "@/config/reducerproperties";

export default {
  name: "Navbar",
  components: { OrganisationTabs, AdminTabs },
  props: {
    showNavItems: Boolean,
    showAdmin: Boolean,
    showDev: Boolean,
    showConsentLists: Boolean,
    showAccountant: Boolean,
    showOrgViews: Boolean
  },
  data() {
    return {
      busy: true,
      guides: []
    };
  },
  created() {
    if (!this.showAdmin) this.getContent();
  },
  methods: {
    getContent() {
      // Use graph query to request only the fields we need
      const query = "{\nguide {\nshorttitle\n}\n}";

      this.$prismic.client
        .query(Prismic.Predicates.at("document.type", "guide"), {
          graphQuery: query
        })
        .then((document) => {
          this.busy = false;
          if (document) {
            this.guides = document.results.map((x) => x);
            this.guides.sort((a, b) =>
              a.data.shorttitle[0].text > b.data.shorttitle[0].text ? 1 : -1
            );
          } else {
            this.$router.push({ name: "not-found" });
          }
        })
        .catch(() => {
          Console.log("Error getting content from CMS");
          this.busy = false;
        });
    },
    logonav() {
      var unauthenticatedRoutes = ["signin", "signup", "demo", "demo-savings"];

      if (unauthenticatedRoutes.indexOf(this.$route.name) > -1) {
        window.location = url.homepage;
      } else {
        this.$router.push({ name: "dashboard" });
      }
    }
  }
};
</script>

<style lang="scss">
#main-navbar {
  @import "@/styles/colours.scss";
  background-color: white;
  border-bottom: 1px solid $color-grey-lighter3;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10;
  margin-bottom: 3em;

  #brand-logo {
    cursor: pointer;
  }
  .navbar-brand img {
    height: 30px;
  }
  .navbar-brand {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .navbar-nav li {
    margin-left: 1em;
    z-index: 1021;
  }

  .navnavnav {
    display: flex;
    align-items: flex-start;
    & > ul {
      width: 400px;
    }
  }

  /* change the brand and text color */
  .navbar-brand,
  .navbar-text {
    color: $color-blue-main;
  }
  /* change the link color */
  .navbar-nav .nav-link {
    color: $color-font-para;
  }
  /* change the color of active or hovered links */
  /* Only apply underline active/hover on devices greater than md (when toggle is not active)*/
  @media (min-width: 768px) {
    .nav-item .active,
    .nav-item:hover .nav-link {
      color: $color-font-para;
      border-bottom: 2px solid $color-blue-main;
      margin-bottom: -2px;
    }
    .nav-link {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .nav-item .dropdown-item,
  .nav-item .dropdown-item .active,
  .nav-item:hover .dropdown-item {
    border: 0;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(100,100,100, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
  .navbar-toggler {
    border-color: $color-grey-lighter2 !important;
  }
  ul.show:focus {
    outline: initial;
  }
}
</style>