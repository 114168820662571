/**
 * To learn more about HTML Serializer check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/html-serializer
 */

import prismicDOM from 'prismic-dom'
import linkResolver from './link-resolver'

const Elements = prismicDOM.RichText.Elements

export default function (type, element, content, children) {


    if (type === Elements.heading1 || type === Elements.heading2) {
        var id = children.toString().toLowerCase().replace(/ /g, '');
        return `<h1 id="${id}">${children}</h1>`;
    }

    // Generate links to Prismic Documents as <router-link> components
    // Present by default, it is recommended to keep this
    if (type === Elements.hyperlink) {
        let result = ''
        const url = prismicDOM.Link.url(element.data, linkResolver)

        if (element.data.link_type === 'Document') {
            result = `<router-link to="${url}">${content}</router-link>`
        } else {
            const target = element.data.target ? `target="'${element.data.target}'" rel="noopener"` : ''
            result = `<a href="${url}" ${target}>${content}</a>`
        }
        return result
    }

    // If the image is also a link to a Prismic Document, it will return a <router-link> component
    // Present by default, it is recommended to keep this
    if (type === Elements.image) {
        let result = `<img src="${element.url}" alt="${element.alt || ''}" copyright="${element.copyright || ''}">`

        if (element.linkTo) {
            const url = prismicDOM.Link.url(element.linkTo, linkResolver)

            if (element.linkTo.link_type === 'Document') {
                result = `<router-link to="${url}">${result}</router-link>`
            } else {
                const target = element.linkTo.target ? `target="${element.linkTo.target}" rel="noopener"` : ''
                result = `<a href="${url}" ${target}>${result}</a>`
            }
        }
        const wrapperClassList = [element.label || '', 'block-img']
        result = `<p class="${wrapperClassList.join(' ')}">${result}</p>`
        return result
    }

    // Return null to stick with the default behavior for everything else
    return null
}