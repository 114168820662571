<template>
  <b-modal
    id="mfa-modal"
    centered
    title="Two-factor authentication"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    :visible="showing"
    @close.prevent="hide"
  >
    <!-- Modal Content -->

    <div v-if="!qrCode" class="d-flex mb-2">
      <div class="m-auto">
        <div class="lock-icon">
          <font-awesome-icon class="text-primary" :icon="['fas', 'lock-alt']" />
        </div>
      </div>
    </div>

    <div v-if="qrCode" class="d-flex flex-column align-items-center px-1 text-center">
      <h6 class="my-3">
        For enhanced security, please set up MFA by scanning the QR code below with your
        Authenticator app.
      </h6>
      <img :src="qrCode" alt="QR Code for MFA" class="mb-3" style="max-width: 70%;" />

      <p v-if="secret">
        Setup key: <b>{{ secret }}</b>
      </p>

      <p class="px-5">
        Once scanned, your app will provide a code
      </p>
    </div>

    <!-- Input for TOTP code -->
    <div class="px-5">
      <div v-if="!qrCode" class="text-center py-2">
        <label for="auth-code"
          >Open Authenticator app on your mobile device and enter the code shown.</label
        >
      </div>
      <b-form @submit.prevent="submitCode" @keyup.enter="submitCode">
        <b-form-input
          id="auth-code"
          v-model="enteredCode"
          placeholder="6 digit code"
          type="number"
          no-wheel
          ref="codeInput"
        ></b-form-input>
        <b-button type="submit" variant="primary" class="mt-3 pb-2" block>Verify</b-button>
      </b-form>
      <b-alert :show="error !== null" variant="danger" class="mt-2 py-2">{{ error }}</b-alert>
      <p class="pt-2 text-center text-small">
        Need help? Contact us on <a href="mailto:support@reducer.co.uk">support@reducer.co.uk</a>
      </p>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "../../components/eventbus";

export default {
  name: "MFAModal",
  props: {
    qrCode: {
      type: String,
      default: null
    },
    codeEntered: {
      type: Function,
      default: () => {}
    },
    secret: String
  },
  data() {
    return {
      title: "Reducer",
      message: null,
      showing: false,
      enteredCode: "",
      error: null
    };
  },
  created() {
    EventBus.$on("show-error-mfa-modal", ({ message }) => (this.error = message));
  },
  methods: {
    async show() {
      if (this.codeEntered) {
        this.showing = true;
        await this.$nextTick();
        this.$refs.codeInput.focus();
      }
    },
    hide() {
      EventBus.$emit("signin-page-stop-loading");
      this.showing = false;
    },
    submitCode() {
      this.error = null;
      this.codeEntered(this.enteredCode);
    }
  }
};
</script>
<style lang="scss">
#mfa-modal {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .lock-icon {
    background-color: rgba(226, 227, 229, 0.95);
    padding: 1.6rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      width: 2rem;
    }
  }
}
</style>
