class RoleHelper {
  static FULL_MEMBER_ID = "full_member";
  static FULL_MEMBER_DESCRIPTION =
    "A full member can view and edit all organisation data, including invoices and savings reports.";
  static REPORT_VIEWER_ID = "report_viewer";
  static REPORT_VIEWER_DESCRIPTION =
    "A report viewer has limited access to an organisation. They can see savings reports, but not invoice data.";

  static description(r) {
    var description = "";
    switch (r.id) {
      case RoleHelper.FULL_MEMBER_ID:
        description = RoleHelper.FULL_MEMBER_DESCRIPTION;
        break;
      case RoleHelper.REPORT_VIEWER_ID:
        description = RoleHelper.REPORT_VIEWER_DESCRIPTION;
        break;
    }
    return description;
  }
}

export default RoleHelper;
