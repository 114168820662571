<template>
  <div>
    <!-- Modals are triggered by event bus -->
    <UnsavedDataModal />
    <MFA
      ref="mfa-modal"
      :qrCode="params.qrCode"
      :codeEntered="params.codeEntered"
      :secret="params.secret"
    />

    <b-modal id="extract-fail-modal" ref="extract-fail-modal" hide-header ok-only no-fade centered>
      <div class="d-block text-center">
        <b-img class="icon" rounded="circle" :src="require('@/assets/images/reducer_pink.png')" />
        <h3 class="mt-3">Error</h3>
        <h4 class="text-muted">
          The requested analysis for {{ params.organisationName }} could not be started
        </h4>
      </div>
    </b-modal>

    <b-modal
      id="extract-unknown-org-modal"
      ref="extract-unknown-org-modal"
      hide-header
      ok-only
      ok-title="Cancel"
      centered
      no-close-on-esc
      :no-close-on-backdrop="params.busy"
      @ok="() => params.onClose()"
    >
      <div class="d-block text-center">
        <b-img
          v-if="params.image == 'xero'"
          class="icon"
          rounded="circle"
          :src="require('@/assets/images/xero.png')"
        />
        <b-img
          v-if="params.image == 'quickBooks'"
          class="icon"
          rounded="circle"
          :src="require('@/assets/images/qb.png')"
        />
        <h4 class="mt-3">{{ params.message }}</h4>
        <div class="m-4">
          <b-form-select
            :disabled="params.busy"
            v-model="params.selected"
            class="connectSelect"
            @input="
              params.onInput(params.selected, params.image);
              params.busy == true;
            "
            :options="params.organisations"
            value-field="organisationId"
            text-field="name"
          >
            <template slot="first">
              <option :value="null">Connected Organisations</option>
            </template>
          </b-form-select>
        </div>
        <Busy v-if="params.busy == true" primary />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import UnsavedDataModal from "@/components/modals/UnsavedDataModal";
import MFA from "@/views/authentication/MFA";

export default {
  name: "GlobalModal",
  data() {
    return {
      modalShown: null,
      params: {}
    };
  },
  components: {
    UnsavedDataModal,
    MFA
  },
  mounted() {
    EventBus.$on("show-global-modal", this.showModal);
    EventBus.$on("hide-global-modal", this.hideModal);
  },
  methods: {
    showModal(id, params) {
      this.modalShown = id;
      this.params = params;
      this.$refs[id].show();
    },
    hideModal(id) {
      this.modalShown = null;
      this.params = {};
      this.$refs[id].hide();
    }
  }
};
</script>
