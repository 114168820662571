<template>
  <b-alert
    id="global-alert"
    class="sticky-top"
    :show="alert.visible"
    :dismissible="alert.dismissable"
    :variant="alert.variant"
    @dismissed="alert.visible = 0"
    @dismiss-count-down="countDownChanged"
    :style="alert.style"
  >
    <span>{{ alert.message }}</span>
  </b-alert>
</template>

<script>
import Console from "@/console";
import { EventBus } from "@/components/eventbus";

export default {
  name: "GlobalAlert",
  data() {
    return {
      alert: {
        visible: 0,
        dismissable: true,
        variant: "info",
        style: ""
      }
    };
  },
  mounted() {
    EventBus.$on("show-alert", (alert) => {
      if (!alert.message) {
        Console.error("Alert was called without a message parameter");
        this.alert.visible = 0;
      } else {
        this.alert.message = alert.message;
        if (alert.visible) {
          this.alert.visible = alert.visible;
        } else {
          this.alert.visible = 10;
        }
        if (alert.variant) {
          this.alert.variant = alert.variant;
        }
        if (alert.style) {
          this.alert.style = alert.style;
        } else {
          this.alert.style = "";
        }
      }
    });
  },
  methods: {
    countDownChanged(sec) {
      this.alert.visible = sec;
    }
  }
};
</script>

<style lang="scss" scoped>
#global-alert {
  margin-top: -3rem !important;
}
</style>