import CognitoAuth from "@/cognito/cognitoauth";
import { EventBus } from "@/components/eventbus";

class AuthHelper {
  //Router Guard for pages that only unauthenticated users can access (like sign-in)
  static async ifNotAuthenticated(to, from, next) {
    //If the user is not authenticated, route to the requested page and finish
    if (!(await CognitoAuth.getCurrentUserAccessToken())) {
      next();
      return;
    }
    EventBus.$emit("refresh-navbar");
    //Else the user is authenticated so route to the dashboard
    next({
      name: "dashboard"
    });
  }

  //Router Guard for pages that require authentication
  static async ifAuthenticated(to, from, next) {
    //If authenticated allow routing to requested page and finish
    if (await CognitoAuth.getCurrentUserAccessToken()) {
      next();
      return;
    }
    //Else stash the requested page for redirection after sign-in
    localStorage.redirect = to.path;
    //If the requested page is a savings report, route to sign-up and finish
    if (to.name === "savings") {
      next({
        name: "signup",
        params: {
          incomingMessage:
            "In order to view the report, please sign-up, or sign-in if you already have an account."
        }
      });
      return;
    }
    //Else route to the sign-in page
    next({
      name: "signin"
    });
  }

  //Router Guard for admin pages
  static async ifAdmin(to, from, next) {
    //If the user is unauthenticated, stash requested page, route to sign-in and finish
    if (!(await CognitoAuth.getCurrentUserAccessToken())) {
      localStorage.redirect = to.path;
      next({
        name: "signin"
      });
      return;
    }
    //Else if the user us an admin, route to the request page and finish
    if (await CognitoAuth.isCurrentUserAdmin()) {
      next();
      return;
    }
    //Else the user is authenticated and not an admin, so route to dashboard
    next({
      name: "dashboard"
    });
  }

  //Router Guard for developer pages
  static async ifDev(to, from, next) {
    //If the user is unauthenticated, stash requested page, route to sign-in and finish
    if (!(await CognitoAuth.getCurrentUserAccessToken())) {
      localStorage.redirect = to.path;
      next({
        name: "signin"
      });
      return;
    }
    //Else if the user us a developer, route to the request page and finish
    if (CognitoAuth.isCurrentUserDev()) {
      next();
      return;
    }
    //Else the user is authenticated and not an admin, so route to dashboard
    next({
      name: "dashboard"
    });
  }
}

export default AuthHelper;
