<template>
  <div id="app">
    <b-alert show dismissible>This is the development and testing instance of reducer.co.uk</b-alert><Navbar
      :show-nav-items="isLoggedIn"
      :show-admin="isAdmin"
      :show-dev="isDev"
      :show-consent-lists="showConsentLists"
      :show-accountant="isAccountant"
      :show-org-views="isOrganisationRoute"
    />
    <GlobalAlert />
    <GlobalToast />
    <router-view />
    <GlobalModal />
    <Footer />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css?family=Encode+Sans:500&display=swap");
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Encode Sans", arial, sans-serif;
}
</style>

<script>
import Navbar from "@/components/nav/Navbar";
import Footer from "@/components/Footer";
import CognitoAuth from "@/cognito/cognitoauth";
import GlobalAlert from "@/components/GlobalAlert";
import GlobalToast from "@/components/GlobalToast";
import GlobalModal from "@/components/GlobalModal";
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import UserSettings from "@/helper/usersettingshelper";

export default {
  name: "ReducerApp",
  components: {
    Navbar,
    Footer,
    GlobalAlert,
    GlobalToast,
    GlobalModal
  },
  mounted() {
    this.refreshNav();

    EventBus.$on("user-signed-out", () => {
      this.refreshNav();
    });
    EventBus.$on("user-signed-in", () => {
      this.refreshNav();
    });
    EventBus.$on("refresh-navbar", () => {
      this.refreshNav();
    });
  },
  data() {
    return {
      isLoggedIn: false,
      isAdmin: false,
      isDev: false,
      showConsentLists: false,
      isAccountant: false
    };
  },
  computed: {
    isOrganisationRoute() {
      if (this.isAdmin) {
        return this.$route.params.organisationId != null;
      }
      return false;
    }
  },
  methods: {
    async refreshNav() {
      this.isLoggedIn = await CognitoAuth.isLoggedIn();
      this.isDev = await CognitoAuth.isCurrentUserDev();
      this.isAdmin = this.isDev || (await CognitoAuth.isCurrentUserAdmin());

      if (this.isLoggedIn & !this.isAdmin) {
        this.isAccountant = await UserSettings.getSetting("accountant", true);

        let client = await ApiHelper.http();
        await client
          .get(`${ApiHelper.endPoint()}consentlists?connectedCheck=true`)
          .then((response) => {
            Console.log(response.data);
            this.showConsentLists = response.data.connected;
          })
          .catch((e) => {
            Console.error(e);
          });
      }
    }
  }
};
</script>