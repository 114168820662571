export const enableIdleVue = true;

export const pricing = {
    baseUrl: "https://pricing.reducer-dev.co.uk/"
}

export const docusign = {
    baseUrl: "https://appdemo.docusign.com/"
};

export const userleap = {
    id: "37NMH0rML"
};

export const mouseflow = {
    id: "1ac28d40-748f-461e-99c4-2afe51935ffe"
};

export const cognito = {
    userPoolId: "us-east-1_ngh6HjWWJ",
    clientId: "1ig3tfhaudlao8rnbdk3qi111u",
    callBackUrl: "https://reducer-dev.co.uk/app/CognitoLanding",
    signoutUrl: "https://reducer-dev.co.uk/app/sign-in",
    endPoint: "https://auth.reducer-dev.co.uk"
};

export const api = {
    dev: false,
    apiEndPoint: "/api/"
};

export const app = {
    type: "reducer",
    name: "Reducer",
    currencySymbol: "£"
};

export const sso = {
    quickbooks: false,
    xero: true,
    google: true
};

export const url = {
    homepage: "https://reducer-dev.co.uk",
    pagenotfound: "https://reducer.co.uk/static-public/error/404.html"
};
