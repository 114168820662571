class AddressHelper {
  static oneLine(value) {
    var line = "";

    if (value != null) {
      var parts = [];

      if (value.address1 != null && value.address1.length > 0) {
        parts.push(value.address1);
      }

      if (value.address2 != null && value.address2.length > 0) {
        parts.push(value.address2);
      }

      if (value.address3 != null && value.address3.length > 0) {
        parts.push(value.address3);
      }

      if (value.city != null && value.city.length > 0) {
        parts.push(value.city);
      }

      if (value.country != null && value.country.length > 0) {
        parts.push(value.country);
      }

      if (value.county != null && value.county.length > 0) {
        parts.push(value.county);
      }

      if (value.postcode != null && value.postcode.length > 0) {
        parts.push(value.postcode);
      }

      line = parts.join(", ");
    }

    return line;
  }

  static COUNTRIES = [
    { value: "AFG", text: "Afghanistan" },
    { value: "ALA", text: "Åland Islands" },
    { value: "ALB", text: "Albania" },
    { value: "DZA", text: "Algeria" },
    { value: "ASM", text: "American Samoa" },
    { value: "AND", text: "Andorra" },
    { value: "AGO", text: "Angola" },
    { value: "AIA", text: "Anguilla" },
    { value: "ATA", text: "Antarctica" },
    { value: "ATG", text: "Antigua and Barbuda" },
    { value: "ARG", text: "Argentina" },
    { value: "ARM", text: "Armenia" },
    { value: "ABW", text: "Aruba" },
    { value: "AUS", text: "Australia" },
    { value: "AUT", text: "Austria" },
    { value: "AZE", text: "Azerbaijan" },
    { value: "BHS", text: "Bahamas" },
    { value: "BHR", text: "Bahrain" },
    { value: "BGD", text: "Bangladesh" },
    { value: "BRB", text: "Barbados" },
    { value: "BLR", text: "Belarus" },
    { value: "BEL", text: "Belgium" },
    { value: "BLZ", text: "Belize" },
    { value: "BEN", text: "Benin" },
    { value: "BMU", text: "Bermuda" },
    { value: "BTN", text: "Bhutan" },
    { value: "BOL", text: "Bolivia, Plurinational State of" },
    { value: "BES", text: "Bonaire, Sint Eustatius and Saba" },
    { value: "BIH", text: "Bosnia and Herzegovina" },
    { value: "BWA", text: "Botswana" },
    { value: "BVT", text: "Bouvet Island" },
    { value: "BRA", text: "Brazil" },
    { value: "IOT", text: "British Indian Ocean Territory" },
    { value: "BRN", text: "Brunei Darussalam" },
    { value: "BGR", text: "Bulgaria" },
    { value: "BFA", text: "Burkina Faso" },
    { value: "BDI", text: "Burundi" },
    { value: "KHM", text: "Cambodia" },
    { value: "CMR", text: "Cameroon" },
    { value: "CAN", text: "Canada" },
    { value: "CPV", text: "Cape Verde" },
    { value: "CYM", text: "Cayman Islands" },
    { value: "CAF", text: "Central African Republic" },
    { value: "TCD", text: "Chad" },
    { value: "CHL", text: "Chile" },
    { value: "CHN", text: "China" },
    { value: "CXR", text: "Christmas Island" },
    { value: "CCK", text: "Cocos (Keeling) Islands" },
    { value: "COL", text: "Colombia" },
    { value: "COM", text: "Comoros" },
    { value: "COG", text: "Congo" },
    { value: "COD", text: "Congo, the Democratic Republic of the" },
    { value: "COK", text: "Cook Islands" },
    { value: "CRI", text: "Costa Rica" },
    { value: "CIV", text: "Côte d'Ivoire" },
    { value: "HRV", text: "Croatia" },
    { value: "CUB", text: "Cuba" },
    { value: "CUW", text: "Curaçao" },
    { value: "CYP", text: "Cyprus" },
    { value: "CZE", text: "Czech Republic" },
    { value: "DNK", text: "Denmark" },
    { value: "DJI", text: "Djibouti" },
    { value: "DMA", text: "Dominica" },
    { value: "DOM", text: "Dominican Republic" },
    { value: "ECU", text: "Ecuador" },
    { value: "EGY", text: "Egypt" },
    { value: "SLV", text: "El Salvador" },
    { value: "GNQ", text: "Equatorial Guinea" },
    { value: "ERI", text: "Eritrea" },
    { value: "EST", text: "Estonia" },
    { value: "ETH", text: "Ethiopia" },
    { value: "FLK", text: "Falkland Islands (Malvinas)" },
    { value: "FRO", text: "Faroe Islands" },
    { value: "FJI", text: "Fiji" },
    { value: "FIN", text: "Finland" },
    { value: "FRA", text: "France" },
    { value: "GUF", text: "French Guiana" },
    { value: "PYF", text: "French Polynesia" },
    { value: "ATF", text: "French Southern Territories" },
    { value: "GAB", text: "Gabon" },
    { value: "GMB", text: "Gambia" },
    { value: "GEO", text: "Georgia" },
    { value: "DEU", text: "Germany" },
    { value: "GHA", text: "Ghana" },
    { value: "GIB", text: "Gibraltar" },
    { value: "GRC", text: "Greece" },
    { value: "GRL", text: "Greenland" },
    { value: "GRD", text: "Grenada" },
    { value: "GLP", text: "Guadeloupe" },
    { value: "GUM", text: "Guam" },
    { value: "GTM", text: "Guatemala" },
    { value: "GGY", text: "Guernsey" },
    { value: "GIN", text: "Guinea" },
    { value: "GNB", text: "Guinea-Bissau" },
    { value: "GUY", text: "Guyana" },
    { value: "HTI", text: "Haiti" },
    { value: "HMD", text: "Heard Island and McDonald Islands" },
    { value: "VAT", text: "Holy See (Vatican City State)" },
    { value: "HND", text: "Honduras" },
    { value: "HKG", text: "Hong Kong" },
    { value: "HUN", text: "Hungary" },
    { value: "ISL", text: "Iceland" },
    { value: "IND", text: "India" },
    { value: "IDN", text: "Indonesia" },
    { value: "IRN", text: "Iran, Islamic Republic of" },
    { value: "IRQ", text: "Iraq" },
    { value: "IRL", text: "Ireland" },
    { value: "IMN", text: "Isle of Man" },
    { value: "ISR", text: "Israel" },
    { value: "ITA", text: "Italy" },
    { value: "JAM", text: "Jamaica" },
    { value: "JPN", text: "Japan" },
    { value: "JEY", text: "Jersey" },
    { value: "JOR", text: "Jordan" },
    { value: "KAZ", text: "Kazakhstan" },
    { value: "KEN", text: "Kenya" },
    { value: "KIR", text: "Kiribati" },
    { value: "PRK", text: "Korea, Democratic People's Republic of" },
    { value: "KOR", text: "Korea, Republic of" },
    { value: "KWT", text: "Kuwait" },
    { value: "KGZ", text: "Kyrgyzstan" },
    { value: "LAO", text: "Lao People's Democratic Republic" },
    { value: "LVA", text: "Latvia" },
    { value: "LBN", text: "Lebanon" },
    { value: "LSO", text: "Lesotho" },
    { value: "LBR", text: "Liberia" },
    { value: "LBY", text: "Libya" },
    { value: "LIE", text: "Liechtenstein" },
    { value: "LTU", text: "Lithuania" },
    { value: "LUX", text: "Luxembourg" },
    { value: "MAC", text: "Macao" },
    { value: "MKD", text: "Macedonia, the former Yugoslav Republic of" },
    { value: "MDG", text: "Madagascar" },
    { value: "MWI", text: "Malawi" },
    { value: "MYS", text: "Malaysia" },
    { value: "MDV", text: "Maldives" },
    { value: "MLI", text: "Mali" },
    { value: "MLT", text: "Malta" },
    { value: "MHL", text: "Marshall Islands" },
    { value: "MTQ", text: "Martinique" },
    { value: "MRT", text: "Mauritania" },
    { value: "MUS", text: "Mauritius" },
    { value: "MYT", text: "Mayotte" },
    { value: "MEX", text: "Mexico" },
    { value: "FSM", text: "Micronesia, Federated States of" },
    { value: "MDA", text: "Moldova, Republic of" },
    { value: "MCO", text: "Monaco" },
    { value: "MNG", text: "Mongolia" },
    { value: "MNE", text: "Montenegro" },
    { value: "MSR", text: "Montserrat" },
    { value: "MAR", text: "Morocco" },
    { value: "MOZ", text: "Mozambique" },
    { value: "MMR", text: "Myanmar" },
    { value: "NAM", text: "Namibia" },
    { value: "NRU", text: "Nauru" },
    { value: "NPL", text: "Nepal" },
    { value: "NLD", text: "Netherlands" },
    { value: "NCL", text: "New Caledonia" },
    { value: "NZL", text: "New Zealand" },
    { value: "NIC", text: "Nicaragua" },
    { value: "NER", text: "Niger" },
    { value: "NGA", text: "Nigeria" },
    { value: "NIU", text: "Niue" },
    { value: "NFK", text: "Norfolk Island" },
    { value: "MNP", text: "Northern Mariana Islands" },
    { value: "NOR", text: "Norway" },
    { value: "OMN", text: "Oman" },
    { value: "PAK", text: "Pakistan" },
    { value: "PLW", text: "Palau" },
    { value: "PSE", text: "Palestinian Territory, Occupied" },
    { value: "PAN", text: "Panama" },
    { value: "PNG", text: "Papua New Guinea" },
    { value: "PRY", text: "Paraguay" },
    { value: "PER", text: "Peru" },
    { value: "PHL", text: "Philippines" },
    { value: "PCN", text: "Pitcairn" },
    { value: "POL", text: "Poland" },
    { value: "PRT", text: "Portugal" },
    { value: "PRI", text: "Puerto Rico" },
    { value: "QAT", text: "Qatar" },
    { value: "REU", text: "Réunion" },
    { value: "ROU", text: "Romania" },
    { value: "RUS", text: "Russian Federation" },
    { value: "RWA", text: "Rwanda" },
    { value: "BLM", text: "Saint Barthélemy" },
    { value: "SHN", text: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "KNA", text: "Saint Kitts and Nevis" },
    { value: "LCA", text: "Saint Lucia" },
    { value: "MAF", text: "Saint Martin (French part)" },
    { value: "SPM", text: "Saint Pierre and Miquelon" },
    { value: "VCT", text: "Saint Vincent and the Grenadines" },
    { value: "WSM", text: "Samoa" },
    { value: "SMR", text: "San Marino" },
    { value: "STP", text: "Sao Tome and Principe" },
    { value: "SAU", text: "Saudi Arabia" },
    { value: "SEN", text: "Senegal" },
    { value: "SRB", text: "Serbia" },
    { value: "SYC", text: "Seychelles" },
    { value: "SLE", text: "Sierra Leone" },
    { value: "SGP", text: "Singapore" },
    { value: "SXM", text: "Sint Maarten (Dutch part)" },
    { value: "SVK", text: "Slovakia" },
    { value: "SVN", text: "Slovenia" },
    { value: "SLB", text: "Solomon Islands" },
    { value: "SOM", text: "Somalia" },
    { value: "ZAF", text: "South Africa" },
    { value: "SGS", text: "South Georgia and the South Sandwich Islands" },
    { value: "SSD", text: "South Sudan" },
    { value: "ESP", text: "Spain" },
    { value: "LKA", text: "Sri Lanka" },
    { value: "SDN", text: "Sudan" },
    { value: "SUR", text: "Suriname" },
    { value: "SJM", text: "Svalbard and Jan Mayen" },
    { value: "SWZ", text: "Swaziland" },
    { value: "SWE", text: "Sweden" },
    { value: "CHE", text: "Switzerland" },
    { value: "SYR", text: "Syrian Arab Republic" },
    { value: "TWN", text: "Taiwan, Province of China" },
    { value: "TJK", text: "Tajikistan" },
    { value: "TZA", text: "Tanzania, United Republic of" },
    { value: "THA", text: "Thailand" },
    { value: "TLS", text: "Timor-Leste" },
    { value: "TGO", text: "Togo" },
    { value: "TKL", text: "Tokelau" },
    { value: "TON", text: "Tonga" },
    { value: "TTO", text: "Trinidad and Tobago" },
    { value: "TUN", text: "Tunisia" },
    { value: "TUR", text: "Turkey" },
    { value: "TKM", text: "Turkmenistan" },
    { value: "TCA", text: "Turks and Caicos Islands" },
    { value: "TUV", text: "Tuvalu" },
    { value: "UGA", text: "Uganda" },
    { value: "UKR", text: "Ukraine" },
    { value: "ARE", text: "United Arab Emirates" },
    { value: "GBR", text: "United Kingdom" },
    { value: "USA", text: "United States" },
    { value: "UMI", text: "United States Minor Outlying Islands" },
    { value: "URY", text: "Uruguay" },
    { value: "UZB", text: "Uzbekistan" },
    { value: "VUT", text: "Vanuatu" },
    { value: "VEN", text: "Venezuela, Bolivarian Republic of" },
    { value: "VNM", text: "Viet Nam" },
    { value: "VGB", text: "Virgin Islands, British" },
    { value: "VIR", text: "Virgin Islands, U.S." },
    { value: "WLF", text: "Wallis and Futuna" },
    { value: "ESH", text: "Western Sahara" },
    { value: "YEM", text: "Yemen" },
    { value: "ZMB", text: "Zambia" },
    { value: "ZWE", text: "Zimbabwe" }
  ];
}

export default AddressHelper;
