import Vue from "vue";
import Router from "vue-router";
import AuthHelper from "./helper/authhelper";
import { url } from "@/config/reducerproperties";

Vue.use(Router);

const baseRoutes = [
  { path: "/", redirect: { name: "dashboard" } },
  {
    path: "/admin/organisation-dashboard/:organisationId",
    name: "organisation-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "organisation-dashboard" */ "./views/admin/OrganisationDashboard.vue"
      ),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/organisation-details/:organisationId",
    name: "organisation-details",
    component: () =>
      import(/* webpackChunkName: "organisation-details" */ "./views/OrganisationDetails.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/organisation-data/:organisationId",
    name: "organisation-data",
    component: () =>
      import(/* webpackChunkName: "organisation-data" */ "./views/OrganisationData.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/organisation-data-upload/:organisationId",
    name: "organisation-data-upload",
    component: () =>
      import(
        /* webpackChunkName: "organisation-data-upload" */ "./views/OrganisationDataUpload.vue"
      ),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/mlink",
    name: "mlink",
    component: () =>
      import(/* webpackChunkName: "magiclink" */ "@/views/authentication/MagicLink.vue")
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import(/* webpackChunkName: "signin" */ "@/views/authentication/Signin.vue"),
    beforeEnter: AuthHelper.ifNotAuthenticated,
    props: true
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import(/* webpackChunkName: "signup" */ "@/views/authentication/Signup.vue"),
    beforeEnter: AuthHelper.ifNotAuthenticated,
    props: true
  },
  {
    path: "/sign-up-xero",
    name: "signupxero",
    component: () =>
      import(/* webpackChunkName: "signupxero" */ "@/views/authentication/XeroSignUp.vue"),
    beforeEnter: AuthHelper.ifNotAuthenticated,
    props: true
  },
  {
    path: "/sign-up-xero/complete",
    name: "signupxerocomplete",
    component: () =>
      import(/* webpackChunkName: "signupxero" */ "@/views/authentication/XeroSignUpComplete.vue"),
    beforeEnter: AuthHelper.ifAuthenticated,
    props: true
  },
  {
    path: "/sign-out",
    name: "signout",
    component: () => import(/* webpackChunkName: "signout" */ "@/views/authentication/Signout.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/verify",
    name: "verify",
    component: () => import(/* webpackChunkName: "verify" */ "@/views/authentication/Verify.vue")
  },
  {
    path: "/resendverification",
    name: "resend-verification",
    component: () =>
      import(
        /* webpackChunkName: "resend-verification" */ "@/views/authentication/ResendVerification.vue"
      )
  },
  {
    path: "/requestpasswordreset",
    name: "request-password-reset",
    component: () =>
      import(
        /* webpackChunkName: "req-passwd-reset" */ "@/views/authentication/RequestPasswordReset.vue"
      )
  },
  {
    path: "/PasswordResetLanding",
    name: "confirm-password-reset",
    component: () =>
      import(
        /* webpackChunkName: "confirm-passwd-reset" */ "@/views/authentication/ConfirmPasswordReset.vue"
      )
  },
  {
    path: "/CognitoLanding",
    name: "cognito-landing",
    component: () =>
      import(/* webpackChunkName: "cognito-landing" */ "@/views/authentication/CognitoLanding.vue"),
    props: true
  },
  {
    path: "/TokenLanding",
    name: "token-landing",
    component: () =>
      import(/* webpackChunkName: "token-landing" */ "@/views/authentication/TokenLanding.vue"),
    props: true
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/admin/organisations",
    name: "all-organisations",
    component: () =>
      import(/* webpackChunkName: "organisations" */ "./views/admin/Organisations.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/users",
    name: "all-users",
    component: () => import(/* webpackChunkName: "users" */ "./views/admin/Users.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/useraccount/:userId",
    name: "user-account",
    component: () => import(/* webpackChunkName: "account" */ "./views/admin/UserAccount.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/userextracts/:userId",
    name: "user-extracts",
    component: () =>
      import(/* webpackChunkName: "user-extracts" */ "./views/admin/UserExtracts.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/organisationextracts/:organisationId",
    name: "organisation-extracts",
    component: () =>
      import(
        /* webpackChunkName: "organisation-extracts" */ "./views/admin/OrganisationExtracts.vue"
      ),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/reports/:organisationId",
    name: "reports",
    component: () => import(/* webpackChunkName: "reports" */ "./views/admin/Reports.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/report/:organisationId/:reportname",
    name: "report",
    component: () => import(/* webpackChunkName: "report" */ "./views/admin/Report.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/savings/:organisationId",
    name: "savings",
    props: route => ({ ...route.params }),
    component: () => import(/* webpackChunkName: "savings-report" */ "./views/Savings.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/demo/:friendlyName",
    name: "dynamic-demo-savings",
    props: route => ({ demo: true, ...route.params }),
    component: () => import(/* webpackChunkName: "savings-report" */ "./views/Savings.vue")
  },
  {
    path: "/admin/savingseditor/:organisationId",
    name: "savings-editor",
    props: route => ({ ...route.params }),
    component: () =>
      import(/* webpackChunkName: "savings-editor" */ "./views/admin/SavingsEditor.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/account",
    name: "account",
    component: () => import(/* webpackChunkName: "account" */ "./views/Account.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/account/callback/:platform",
    name: "accountcallback",
    component: () =>
      import(/* webpackChunkName: "account-callback" */ "./views/AccountCallback.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/spotlight/:organisationId",
    name: "spotlight",
    component: () => import(/* webpackChunkName: "spotlight" */ "./views/Spotlight.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/extract/callback/:platform",
    name: "extract-callback",
    component: () =>
      import(/* webpackChunkName: "extract-callback" */ "./views/extract/Callback.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/myconsentlists",
    name: "consent-lists",
    component: () =>
      import(/* webpackChunkName: "consent-lists" */ "./views/consent/ConsentLists.vue"),
    beforeEnter: AuthHelper.ifAuthenticated,
    props: true
  },
  {
    path: "/consentlist/:consentListId",
    name: "consent-list-data",
    component: () =>
      import(/* webpackChunkName: "consent-list-data" */ "./views/consent/ConsentListData.vue"),
    beforeEnter: AuthHelper.ifAdmin,
    props: true
  },
  {
    path: "/consentlist/consents/:consentListId",
    name: "consents",
    component: () => import(/* webpackChunkName: "consents" */ "./views/consent/ConsentData.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/consent/:consentListId",
    name: "consent",
    component: () => import(/* webpackChunkName: "consent" */ "./views/consent/Consent.vue")
  },
  {
    path: "/savings-explorer",
    name: "savings-explorer",
    component: () =>
      import(/* webpackChunkName: "savings-explorer" */ "./views/SavingsExplorer.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/admin/savings-explorer/:userId",
    name: "admin-savings-explorer",
    component: () =>
      import(/* webpackChunkName: "savings-explorer" */ "./views/SavingsExplorer.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/savings-explorer",
    name: "admin-organisations-savings-explorer",
    component: () =>
      import(/* webpackChunkName: "orgs-savings-explorer" */ "./views/admin/SavingsExplorer.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/consentlists/:userId",
    name: "admin-consent-lists",
    component: () =>
      import(/* webpackChunkName: "consent-lists" */ "./views/consent/ConsentLists.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/developer",
    name: "developer",
    component: () => import(/* webpackChunkName: "developer" */ "./views/admin/Developer.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/organisationevents/:organisationId",
    name: "organisation-events",
    component: () =>
      import(/* webpackChunkName: "organisation-events" */ "./views/admin/OrganisationEvents.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/invoices/",
    name: "all-invoices",
    component: () => import(/* webpackChunkName: "invoices" */ "./views/admin/Invoices.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/email",
    name: "email",
    component: () => import(/* webpackChunkName: "email" */ "./views/admin/Email.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/emailevents",
    name: "email-events",
    component: () => import(/* webpackChunkName: "email-events" */ "./views/admin/EmailEvents.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/emailevents/:emailAddress",
    name: "email-events-withEmail",
    component: () => import(/* webpackChunkName: "email-events" */ "./views/admin/EmailEvents.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/scheduledEmails",
    name: "scheduled-emails",
    component: () =>
      import(/* webpackChunkName: "scheduled-emails" */ "./views/admin/ScheduledEmails.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/magiclinks",
    name: "magic-links",
    component: () => import(/* webpackChunkName: "magic-links" */ "./views/admin/MagicLinks.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/extracts",
    name: "all-extracts",
    component: () => import(/* webpackChunkName: "extracts" */ "./views/admin/Extracts.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/suppliers",
    name: "get-suppliers",
    component: () => import(/* webpackChunkName: "suppliers" */ "./views/admin/Suppliers.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/expiringdeals",
    name: "admin-get-expiring-deals",
    component: () =>
      import(/* webpackChunkName: "expiring-deals" */ "./views/admin/ExpiringDeals.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/contractstracker",
    name: "admin-get-contracts",
    component: () =>
      import(/* webpackChunkName: "contracts-tracker" */ "./views/admin/ContractsTracker.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/supplier/:supplierId",
    name: "admin-supplier-edit",
    component: () =>
      import(/* webpackChunkName: "supplier-edit" */ "./views/admin/SupplierEdit.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/supplier/",
    name: "admin-supplier-create",
    component: () =>
      import(/* webpackChunkName: "supplier-edit" */ "./views/admin/SupplierEdit.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/supplier/:supplierId",
    name: "supplier-details",
    component: () =>
      import(/* webpackChunkName: "supplier-details" */ "./views/SupplierDetails.vue"),
    beforeEnter: AuthHelper.ifAuthenticated
  },
  {
    path: "/demo",
    name: "demo",
    props: { demo: true },
    component: () => import(/* webpackChunkName: "demo-report" */ "./views/Savings.vue")
  },
  {
    path: "/demosavings",
    name: "demo-savings",
    props: { demo: true },
    component: () => import(/* webpackChunkName: "demo-report" */ "./views/Savings.vue")
  },
  {
    path: "/admin/requested-jobs",
    name: "requested-jobs",
    component: () =>
      import(/* webpackChunkName: "requested-jobs" */ "./views/admin/RequestedJobs.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/textracts",
    name: "textracts",
    component: () => import(/* webpackChunkName: "textracts" */ "./views/admin/Textracts.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/textracts/:organisationId",
    name: "textract-result",
    component: () =>
      import(/* webpackChunkName: "textract-result" */ "./views/admin/TextractResult.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/spotlight-rules",
    name: "spotlight-rules",
    component: () =>
      import(/* webpackChunkName: "spotlight-rules" */ "./views/admin/SpotlightRules.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/pricing-hub",
    name: "pricing-hub",
    component: () =>
      import(/* webpackChunkName: "textract-result" */ "./views/admin/PricingHub.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/payments/:contractId",
    name: "contract-payments",
    component: () =>
      import(/* webpackChunkName: "contract-payments" */ "./views/admin/ContractPayments.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/sales",
    name: "sales",
    component: () => import(/* webpackChunkName: "sales" */ "./views/admin/sales/Sales.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "/admin/sales/record/:organisationId",
    name: "sales-record",
    component: () =>
      import(/* webpackChunkName: "sales-record" */ "./views/admin/sales/SalesRecord.vue"),
    beforeEnter: AuthHelper.ifAdmin
  },
  {
    path: "*",
    name: "not-found",
    beforeEnter: () => (window.location = url.pagenotfound)
  }
];

//New route files can be concatenated to base routes here
const routes = baseRoutes;

export default new Router({
  mode: "history",
  base: "/app/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
