import { api } from "./config/reducerproperties";

class DummyConsole {
  log() { }
  warn() { }
  error() { }
  info() { }
}

let Console = api.dev ? console : new DummyConsole();

export default Console;
