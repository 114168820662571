<template>
  <div class="toaster-wrapper">
    <b-toaster name="global-toaster"></b-toaster>
    <AnalysisToast />
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import AnalysisToast from "@/components/AnalysisToast";

export default {
  name: "GlobalToast",
  components: { AnalysisToast },
  mounted() {
    EventBus.$on("show-toast", this.createToast);
  },
  methods: {
    // Params for <b-toast>
    createToast(params = {}) {
      if (params.message && params.message != "") {
        // Set title
        if (params.variant && !params.title) {
          if (params.variant == "success") {
            params.title = "Success";
          } else if (params.variant == "warning" || params.variant == "danger") {
            params.title = "Oops!";
            params.autoHideDelay = 12000;
          }
        }
        if (params.message.charAt(params.message.length - 1) != ".") {
          params.message += ".";
        }
        params.toaster = "global-toaster";
        params.toastClass = "reducer-toast";

        this.$bvToast.toast(params.message, params);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.toaster-wrapper {
  position: sticky;
  top: 2.9rem;
  z-index: 9;
  max-width: 350px;
  margin-left: auto;
}
#global-toaster {
  .b-toaster-slot {
    position: absolute;
    top: -2.7rem;
    left: 0;
    right: 0;
    margin-left: auto;
    // pointer-events: none;
  }
  .b-toast {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  .reducer-toast {
    margin-left: auto;
    .toast-body {
      background-color: rgba($color: white, $alpha: 0.85) !important;
    }
  }
}
</style>