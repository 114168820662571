// Make the app properties globally available
import { app } from "@/config/reducerproperties";
if (app) {
  Vue.prototype.$appType = app.type;
  Vue.prototype.$appName = app.name;
  Vue.prototype.$currencySymbol = app.currencySymbol;
}

// Fetch polyfill required by Cognito SDK for authentication pages
import "cross-fetch/polyfill";
// Intersectional observer required to IE compatibility with Bootstrap-vue. Can probably remove if we don't support IE.
import "intersection-observer/intersection-observer";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Idle plugin
import IdleVue from "idle-vue";
// Required for idle
import { EventBus } from "@/components/eventbus";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import jsPDF from "jspdf";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./prismic/link-resolver";
import htmlSerializer from "./prismic/html-serializer";
import FormatHelper from "./helper/formathelper";
import RoleHelper from "./helper/rolehelper";
import AddressHelper from "./helper/addresshelper";
import PhoneHelper from "./helper/phonehelper";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPaperclip,
  faTrash,
  faTrashAlt,
  faInfoCircle,
  faPoundSign,
  faTable,
  faExclamationCircle,
  faCubes,
  faUser,
  faHandSpock,
  faFileInvoiceDollar,
  faFileUpload,
  faCalendarCheck,
  faShareAlt,
  faShareAltSquare,
  faBars,
  faDumpster,
  faGasPump,
  faTint,
  faTimesCircle,
  faUserCheck,
  faUserSlash,
  faCircle,
  faSortDown,
  faStar,
  faStarHalfAlt,
  faCalculator,
  faSave,
  faRecycle,
  faMobileAlt,
  faCheckCircle as faCheckCircleSolid,
  faInfo,
  faPlus,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faHandshake,
  faCoins,
  faComments,
  faHeart,
  faEyeSlash,
  faMagic,
  faInfinity,
  faCheck,
  faEllipsisV,
  faSimCard,
  faVideo,
  faExclamationTriangle,
  faWifi,
  faPhoneAlt,
  faSync,
  faSortNumericUpAlt,
  faSortNumericDownAlt,
  faQuestion,
  faSeedling as faSeedlingSolid,
  faUndo as faUndoSolid,
  faEraser,
  faPaintBrush,
  faMagnet,
  faMobile as faMobileSolid,
  faThumbsDown,
  faFileContract,
  faPlayCircle,
  faHotel,
  faCaretDown,
  faCaretUp
} from "@fortawesome/free-solid-svg-icons";
import {
  faClipboard as farClipboard,
  faEdit as farEdit,
  faCheckCircle,
  faEye,
  faQuestionCircle,
  faCompass,
  faSave as faSaveReg,
  faClock,
  faTrashAlt as faTrashAltRegular
} from "@fortawesome/free-regular-svg-icons";
import {
  faWhale,
  faPhoneOffice,
  faLightbulbOn,
  faCreditCardFront,
  faUnicorn,
  faGlobeAmericas,
  faLockAlt,
  faLockOpenAlt,
  faExternalLink,
  faCreditCard as faCreditCardSolid,
  faRocketLaunch,
  faAlienMonster,
  faArrowAltLeft,
  faArrowToTop,
  faFish,
  faNarwhal,
  faArrowCircleDown,
  faCloudUpload,
  faChartNetwork
} from "@fortawesome/pro-solid-svg-icons";
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import {
  faStarHalf,
  faSimCard as faSimCardDuotone,
  faLink,
  faLockAlt as faLockAltDuo,
  faPlusCircle,
  faMobile,
  faThumbsUp as faThumbsUpDuo,
  faInfo as faInfoDuo
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faGlobe,
  faFile,
  faFileCheck,
  faSave as faSaveLight,
  faFileExcel,
  faFileSignature,
  faCopy,
  faUndo,
  faStar as faStarLight,
  faSeedling,
  faExclamationCircle as faExclamationCircleLight,
  faTrashAlt as faTrashAltLight,
  faThumbsUp,
  faTag,
  faFilter,
  faSimCard as faSimCardLight,
  faUnicorn as faUnicornLight,
  faFileMedical,
  faRecycle as faRecycleLight,
  faBurn as faBurnLight,
  faRouter as faRouterLight,
  faLightbulbOn as faLightbulbOnLight,
  faGasPump as faGasPumpLight,
  faCreditCard as faCreditCardLight,
  faTint as faTintLight,
  faMobile as faMobileLight,
  faCut,
  faBirthdayCake as faBirthdayCakeLight,
  faSurprise as faSurpriseLight,
  faTrashUndoAlt,
  faWhale as faWhaleLight,
  faPlus as faPlusLight,
  faArrowCircleUp,
  faFileAlt,
  faDollarSign
} from "@fortawesome/pro-light-svg-icons";

import {
  faMinusCircle,
  faLightbulbOn as faLightbulb,
  faFireAlt,
  faTint as faTintReg,
  faCreditCard,
  faPhoneVolume,
  faGasPump as faGasPumpReg,
  faChevronDoubleDown,
  faFillDrip,
  faMarker,
  faTrashRestoreAlt,
  faBolt,
  faPencil,
  faLongArrowUp
} from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faDollarSign,
  faLongArrowUp,
  faPlayCircle,
  faHotel,
  faPencil,
  faFileAlt,
  faChartNetwork,
  faFileContract,
  faArrowCircleUp,
  faPlusLight,
  faCloudUpload,
  faBolt,
  faTrashRestoreAlt,
  faTrashUndoAlt,
  faWhaleLight,
  faFillDrip,
  faThumbsDown,
  faNarwhal,
  faFish,
  faInfoDuo,
  faThumbsUpDuo,
  faLockAltDuo,
  faPaintBrush,
  faEraser,
  faMagnet,
  faFileMedical,
  faMobileSolid,
  faMobileLight,
  faTintLight,
  faCreditCardLight,
  faGasPumpLight,
  faRecycleLight,
  faRouterLight,
  faBurnLight,
  faLightbulbOnLight,
  faArrowAltLeft,
  faArrowToTop,
  faMobile,
  faQuestion,
  faSimCardDuotone,
  faSimCardLight,
  faVideo,
  faPaperclip,
  faTrash,
  faTrashAlt,
  faInfoCircle,
  faPoundSign,
  faTable,
  faExclamationCircle,
  faCubes,
  faUser,
  faHandSpock,
  faFile,
  faFileCheck,
  faFileInvoiceDollar,
  faFileUpload,
  faFileExcel,
  faFileSignature,
  faCalendarCheck,
  faShareAlt,
  faShareAltSquare,
  faBars,
  faDumpster,
  faGasPump,
  faTint,
  farClipboard,
  farEdit,
  faWhale,
  faPhoneOffice,
  faLightbulbOn,
  faCreditCardFront,
  faCreditCardSolid,
  faTimesCircle,
  faCheckCircle,
  faUserCheck,
  faEye,
  faEyeSlash,
  faUserSlash,
  faLink,
  faCircle,
  faMinusCircle,
  faQuestionCircle,
  faUnicorn,
  faPlusCircle,
  faFacebookF,
  faTwitter,
  faGlobeAmericas,
  faGlobe,
  faSortDown,
  faLinkedinIn,
  faStar,
  faStarHalfAlt,
  faStarHalf,
  faStarLight,
  faUndo,
  faCalculator,
  faSaveLight,
  faSave,
  faLightbulb,
  faFireAlt,
  faTintReg,
  faCreditCard,
  faPhoneVolume,
  faRecycle,
  faGasPumpReg,
  faMobileAlt,
  faCheckCircleSolid,
  faLockAlt,
  faLockOpenAlt,
  faInfo,
  faPlus,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faHandshake,
  faCoins,
  faComments,
  faHeart,
  faExternalLink,
  faCopy,
  faMagic,
  faInfinity,
  faCompass,
  faCheck,
  faEllipsisV,
  faExclamationCircleLight,
  faSimCard,
  faBars,
  faRocketLaunch,
  faExclamationTriangle,
  faTag,
  faThumbsUp,
  faSeedling,
  faWifi,
  faPhoneAlt,
  faSaveReg,
  faTrashAltLight,
  faClock,
  faSync,
  faAlienMonster,
  faFilter,
  faSortNumericUpAlt,
  faSortNumericDownAlt,
  faSeedlingSolid,
  faUndoSolid,
  faUnicornLight,
  faTrashAltRegular,
  faChevronDoubleDown,
  faCut,
  faMarker,
  faBirthdayCakeLight,
  faSurpriseLight,
  faArrowCircleDown,
  faCaretDown,
  faCaretUp
);

import vueHeadful from "vue-headful";
import VueClipboard from "vue-clipboard2";
import Multiselect from "vue-multiselect";
import { enableIdleVue } from "./config/reducerproperties";

//Set the user access token from local storage
import axios from "axios";
import CognitoAuth from "./cognito/cognitoauth";

CognitoAuth.getCurrentUserAccessToken().then(accessToken => {
  if (accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  }
});

// Vue multiselect https://vue-multiselect.js.org/#sub-props
// Use styles and component globally
require("vue-multiselect/dist/vue-multiselect.min.css");
Vue.component("multiselect", Multiselect);

// Idle Vue plugin
if (enableIdleVue) {
  Vue.use(IdleVue, {
    eventEmitter: EventBus,
    idleTime: 1800 * 1000 // half an hour
  });
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(jsPDF);
Vue.use(VueClipboard);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-headful", vueHeadful);
Vue.use(PrismicVue, {
  endpoint: "https://reducer.prismic.io/api/v2",
  linkResolver,
  htmlSerializer
});

Vue.filter("date", FormatHelper.formatDate);
Vue.filter("address", AddressHelper.oneLine);
Vue.filter("phone", PhoneHelper.phone);
Vue.filter("roleDescription", RoleHelper.description);
Vue.filter("currency", FormatHelper.formatCurrency);
Vue.filter("savingsstate", FormatHelper.formatSavingsState);
new Vue({
  router,
  store,
  render: h => h(App),

  async onIdle() {
    if (await CognitoAuth.isLoggedIn()) {
      CognitoAuth.signout();
      EventBus.$emit("show-toast", {
        message: "You've been signed out after 30 minutes of inactivity.",
        variant: "info"
      });
    }
  }
}).$mount("#app");
