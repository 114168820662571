import { api } from "../config/reducerproperties";
import CognitoAuth from "../cognito/cognitoauth";
import axios from "axios";

class ApiHelper {
  static ALL_ITEMS = 2147483647;
  static DEFAULT_INVOICE_COUNT = 200;

  static endPoint() {
    if (api.dev) {
      return api.devApiEndPoint;
    } else {
      return api.apiEndPoint;
    }
  }

  static appendParam(params, key, value) {
    var modified = params;

    if (value != null) {
      if (!modified || modified === "") {
        modified = "?";
      } else {
        modified += "&";
      }

      modified += `${key}=${value}`;
    }

    return modified;
  }

  static toMap(collection, idGetter) {
    var map = {};
    for (var item of collection) {
      map[idGetter(item)] = item;
    }
    return map;
  }

  static async http() {
    var accessToken = await CognitoAuth.getCurrentUserAccessToken();
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    }

    return axios;
  }
}

export default ApiHelper;
