class PhoneHelper {
  static RE = /^\+?[\d| ]{10,14}$/;

  static phone(value) {
    var line = "";

    if (value != null) {
      if (typeof value == "string") {
        line = value;
      } else if (typeof value == "object") {
        line = value.number;
      }
    }

    return line;
  }

  static isValid(value) {
    return PhoneHelper.RE.test(value);
  }
}

export default PhoneHelper;
