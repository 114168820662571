import ApiHelper from "./apihelper";
import CognitoAuth from "@/cognito/cognitoauth";
import Console from "@/console";
import { EventBus } from "../components/eventbus";

/**
 * @file
 * Fetching and persiting current user's settings in local storage.
 * Automatically refetch settings if needed.
 */
class UserSettingsHelper {

  static settingPrefix(settingName) {
    return "reducer-settings." + settingName;
  }

  static setLocalStorage(k, v) {
    localStorage.setItem(this.settingPrefix(k), v);
  }

  static getLocalStorage(k) {
    return localStorage.getItem(this.settingPrefix(k));
  }

  static removeLocalStorage(k) {
    localStorage.removeItem(this.settingPrefix(k));
  }

  static async fetchSettings() {
    let userToken = await CognitoAuth.currentUserParsedToken();
    let client = await ApiHelper.http();

    return client.get(`${ApiHelper.endPoint()}users/${userToken.sub}/settings`)
      .then(response =>  this.saveToLocalStorage(response.data.userSettings))
      .catch(e => Console.error(e));
  }

  /**
   * Save user data local storage and the database.
   * 
   * @param {object} user
   */
  static async saveUserData(user) {
    let userToken = await CognitoAuth.currentUserParsedToken();
    let client = await ApiHelper.http();

    client.post(`${ApiHelper.endPoint()}users/${userToken.sub}`, { user: user })
      .then(response => this.saveToLocalStorage(response.data.user.settings))
      .catch(e => Console.error(e));
  }

  /**
   * Store every settings entry in local storage.
   * Store setting object, so settings can be deleted before refecthing.
   * Store time of settings being set.
   *  
   * @param {object} settings
   *    UserSettings object. 
   */
  static saveToLocalStorage(settings) {
    this.removeSettings();

    if (settings) {
      Object.entries(settings).forEach(([k, v]) =>
        this.setLocalStorage(k, JSON.stringify(v))
      );
      this.setLocalStorage("userSettings", JSON.stringify(settings))
    }
    this.setLocalStorage("userSettingsSetTime", new Date().getTime());
  }

  /**
   * Get an item from local storage. 
   * Refresh the settings if refresh is set to true they need refreshing.
   * 
   * @param {string} settingName 
   *    Key of the item stored in local storage.
   * @param {boolean} refresh 
   *    If set to true, wait for refresh to complete before returning the result.
   */
  static async getSetting(settingName, refresh = false) {
    if (refresh || this.needsRefresh()) {
      await this.fetchSettings();
    }
    return JSON.parse(this.getLocalStorage(settingName));
  }

  /**
   * Check if settings need refreshing.
   * 
   * @return true if settings have not been set or been set more than 24 hours ago.
   */
  static needsRefresh() {
    let settingsSet = this.getLocalStorage("userSettingsSetTime");
    if (settingsSet) {
      let settingsSetTime = parseInt(settingsSet);
      if (!isNaN(settingsSetTime)) {
        let settingsExpireTime = 86400000; // 24 hours
        return (new Date().getTime() - settingsSetTime) > settingsExpireTime;
      }
    }
    return true;
  }

  /**
   * Remove user settings from local storage
   */
  static removeSettings() {
    let oldSettings = JSON.parse(this.getLocalStorage("userSettings"));
    if (oldSettings != null) {
      Object.keys(oldSettings).forEach((k) => this.removeLocalStorage(k));
      this.removeLocalStorage("userSettings")
    }
    this.removeLocalStorage("userSettingsSetTime");
  }
}

export default UserSettingsHelper;